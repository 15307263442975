<template>
  <div>
    <CooperativeRegistration />
  </div>
</template>

<script>
import CooperativeRegistration from '@/nfmp_components/CooperativeRegistration.vue'
export default {
  components: {
    CooperativeRegistration
  }
}
</script>