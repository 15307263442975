export default [{
  text: '',
  items: [
    { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard'  },
    { icon: 'mdi-chart-bar', key: 'menu.blank', text: 'Quaterly Report(QR) Filing', link: '/quaterlyReportFiling' },
    { icon: 'mdi-file-hidden', key: 'menu.blank', text: 'Zero Report Filing', link: '/zeroFiling' },
    { icon: 'mdi-certificate', key: 'menu.blank', text: 'QR Clearance Certificate', link: '/clearanceCertificate' },
    { icon: 'mdi-sprout', key: 'menu.blank', text: 'Farmers Registration', link: '/farmerRegistration' },
    { icon: 'mdi-test-tube', key: 'menu.blank', text: 'Product Evaluation', link: '/productevaluation' },
    { icon: 'mdi-receipt', key: 'menu.blank', text: 'Ledger', link: '' },
    
    { icon: 'mdi-cash-multiple', key: 'menu.blank', text: 'Transaction History', link: '/transactionhistory' },
    { icon: 'mdi-bell', key: 'menu.blank', text: 'Notification', link: '/notification' }  
  ]
}]