<template>
  <div>
    <FarmersVerifier />
  </div>
</template>

<script>
import FarmersVerifier from '@/nfmp_components/FarmersVerifier.vue'
export default {
  components: {
    FarmersVerifier
  }
}
</script>