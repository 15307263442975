<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Cooperative/Groups Registration</h1>
      <v-divider class="my-2"></v-divider>
    </div>

    <div v-if="hasActiveAccount">
      <v-form ref="form" v-model="valid" fast-fail>
        <v-card class="mb-3">
          <v-card-title>Cooperative/Group details</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="8">
                <v-text-field
                  v-model="cooperative_name"
                  label="Cooperative Name *"
                  :rules="namesRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select
                  v-model="cooperative_type"
                  return-object
                  item-text="title"
                  item-value="id"
                  :items="cooperative_type_arr"
                  :rules="[(v) => !!v || 'Item is required']"
                  label="Cooperative Type *"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  v-model="state"
                  return-object
                  item-text="title"
                  item-value="id"
                  :items="state_arr"
                  :rules="[(v) => !!v || 'Item is required']"
                  label="Cooperative State *"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-select
                  v-model="lga"
                  return-object
                  item-text="title"
                  item-value="id"
                  :items="lga_arr"
                  :rules="[(v) => !!v || 'Item is required']"
                  label="Cooperative LGA *"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="address"
                  :rules="addressRules"
                  label="Cooperative Address *"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-3">
          <v-card-title>Cooperative Contact details</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="first_name"
                  label="Contact First Name *"
                  :rules="namesRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="surname"
                  label="Contact Surname *"
                  :rules="namesRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  :counter="11"
                  label="Contact Phone Number *"
                  maxlength="11"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="email"
                  label="Contact email "
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-3">
          <v-card-title>Value Chain Commodities</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-chip-group
                  v-model="selections"
                  center-active
                  multiple
                  column
                  :rules="selectionsRules"
                  active-class="green--text text--accent-5 "
                >
                  <v-chip
                    v-for="item in comodities_arr"
                    :key="item.id"
                    :value="item.title"
                    filter
                    outlined
                  >
                    {{ item.title }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="6" class="text-center">
            <v-btn
              rounded
              block
              color="primary"
              large
              @click="showDialog()"
            >
              Submit 
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-toolbar color="green" dark dense flat>
          <v-toolbar-title class="text-h5">
            Save Cooperative
          </v-toolbar-title>
        </v-toolbar>
        <v-card-subtitle class="mt-3">Are sure you want to save this record?</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close">
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            outlined
            @click="postData()"
          >
            Save Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
import moment from 'moment'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:'',
      loading: false,
      valid:'',
      postStatus:'new',
      dialog:false,
      hasActiveAccount:false,

      cooperative_name:'',
      cooperative_type:'',
      selections:[],
      comodities_arr:[],

      cooperative_type_arr:[],
      state_arr:[],
      lga_arr:[],
      all_lga_arr:[],
      state:'',
      lga:'',
      address:'',
      first_name:'',
      surname:'',
      phone:'',
      email:'',

      corperativeNameRules: [
        (v) => !!v || 'Corperative name is required',
        (v) =>
          (v && v.length >= 4) ||
        'Corperative name must be greater than 4 characters'
      ],
      namesRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) ||
        'Name must be greater than 2 characters'
      ],
      selectionsRules: [
        (v) => (v && v.length <= 11) || 'One or more comodities must be selected'
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
        (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      addressRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 15) ||
        'Mame must be greater than 15 characters'
      ]
    }
  },
  watch: {
    state: {
      handler(newvalue) {
        if (newvalue !== undefined) {
          this.lga_arr = this.all_lga_arr.filter(
            (lga) => lga.state_id === this.state.id
          )
        }
      },
      immediate: true
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.work_state = this.memberData[0].work_state
    this.enumeratorId = this.memberData[0].id
    this.startDate = this.memberData[0].startDate 
    this.stopDate = this.memberData[0].stopDate
    const stopDate = moment(this.stopDate)
    const today = moment(new Date())

    this.hasActiveAccount = moment(stopDate).isAfter(today, 'day')
    this.getFormOption()
  },
  mounted() {},
  methods: {
    close() {
      this.dialog = false
    },
    showDialog: function() {
      this.dialog = true
    },
    getFormOption: function() {
      const formUrl = httpServ.getFarmerEnumerationtUrl('/wsg/drySession/formOptions/cooperative')
      const resValue2 = httpServ.makeRequest(formUrl, 'GET', { 'enumeratorId':this.memberData[0].id,'mode':this.memberData[0].mode })

      resValue2.then((result) => {
        //console.log('our select is ', result)
        if (result.status === 200) {
          this.state_arr = result.data.states
          this.all_lga_arr = result.data.lgas
          this.comodities_arr = result.data.comodities
          this.cooperative_type_arr = result.data.cooperatives
        }
      })
    },
    clearPostData: function() {
      this.cooperative_name  = ''
      this.cooperative_type.title = ''
      this.selections = ''
      this.state = ''
      this.lga = ''
      this.address  = ''
      this.first_name = ''
      this.surname = ''
      this.phone = ''
      this.email = ''
    },
    getNewData: function() {
      return {
        status: 'new',
        enumeratorId: this.memberData[0].id,
        cooperative_name:this.cooperative_name,
        cooperative_type:this.cooperative_type.title,
        commodities:this.selections,
        state:this.state.title,
        lga:this.lga.title,
        address:this.address,
        contact_first_name:this.first_name,
        contact_surname:this.surname,
        contact_phone:this.phone,
        contact_email:this.email,
        mode:this.memberData[0].mode
      }
    },
    getUpdatedData: function() {
      return {
        status: 'updated',
        enumerator:'test',
        cooperative_name:this.cooperative_name,
        cooperative_type:this.cooperative_type,
        commodities:this.selections,
        state:this.state,
        lga:this.lga,
        address:this.address,
        first_name:this.first_name,
        surname:this.surname,
        phone:this.phone,
        email:this.email,
        mode:this.memberData[0].mode
      }
    },
    postData: function() {
      this.close()

      //validate selection
      if (this.selections.length === 0) {
        this.snackbartext = 'One or more comodity must be selected!'
        this.snackbar = true

        return
      }
     
      const postData = this.postStatus === 'new' ? this.getNewData() : this.getUpdatedData()

      this.loading = true
      if (this.$refs.form.validate()) {
    
        const url = httpServ.getFarmerEnumerationtUrl('/wsg/drySession/saveCooperative')
        const resp = httpServ.postFetch(url,postData)

        resp.then((result ) => {
          this.loading = false
          this. clearPostData()
          this.$refs.form.reset()
          this.$refs.form.resetValidation()
          if (result.status === 200) {
            this.snackbartext = 'Your submission was successful'
            this.snackbar = true
            //window.location.reload()
          } else if (result.status === 422) {
            
            if (result instanceof SyntaxError) {
              this.snackbartext = result.message
              this.snackbar = true
            } else {
              //console.log('There was an error', result) //Checker
              this.snackbartext = result.message
              this.snackbar = true

            }
            this.formBtnLoader = false
          } else {
            this.snackbartext = result.message 
            this.snackbar = true
          }
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
