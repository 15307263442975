<template>
  <div>
    <FarmersRegistration />
  </div>
</template>

<script>
import FarmersRegistration from '@/nfmp_components/FarmersRegistration.vue'
export default {
  components: {
    FarmersRegistration
  }
}
</script>