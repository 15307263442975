<template>
  <v-row dense>
    <v-col cols="12" sm="12" class="">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="tableData"
          sort-by="id"
          class="elevation-1"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
          fixed-header
          height="350px"
          :no-data-text="tableNoDataText"
        >
          <template v-slot:item.actions="{ item }" >
            <v-icon class="mr-2" @click="editCoop(item)" >
              mdi-file-document-edit
            </v-icon> 
          </template>
          <template v-slot:no-data>
            <div class="caption">
              This table has no record! Try reloading it
            </div>
            <v-btn color="primary" outlined small @click="getTableData">
              Reload Data
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import httpServ from '../../services/HTTPService'
import moment from 'moment'

export default {
  components: {
    //
  },
  data: () => ({
    isNotLoaded: true,
    absolute: true,
    opacity: 1,
    overlay: false,
    dense: false,
    fixedHeader: false,
    height: 300,
    loading: false,

    tableLoading: true,
    singleSelect: true,
    tableNoDataText: 'Thus table has no record!',
    
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'cooperative_name'
      },
      { text: 'Type', value: 'cooperative_type' },
      { text: 'State', value: 'state' },
      { text: 'LGA', value: 'lga' },
      { text: 'Commodities', value: 'commodities' },   
      { text: 'Modify', value: 'actions', sortable: false }
    ],
    tableData: [],
    editedIndex: -1,
    
    items: [],

    corperativeNameRules: [
      (v) => !!v || 'Corperative name is required',
      (v) =>
        (v && v.length >= 4) ||
        'Corperative name must be greater than 4 characters'
    ],
    namesRules: [
      (v) => !!v || 'Name is required',
      (v) =>
        (v && v.length >= 2) ||
        'Mame must be greater than 2 characters'
    ],
    phoneRules: [
      (v) => !!v || 'Phone Number is required',
      (v) => /^\d{11}$/.test(v) || 'Phone number be must be valid',
      (v) => (v && v.length <= 11) || 'Phone Number must be 11 figures'
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    addressRules: [
      (v) => !!v || 'Name is required',
      (v) =>
        (v && v.length >= 15) ||
        'Mame must be greater than 15 characters'
    ]
  }),
  /* eslint-disable */
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.work_state = this.memberData[0].work_state
    this.enumeratorId = this.memberData[0].id
    this.startDate = this.memberData[0].startDate 
    this.stopDate = this.memberData[0].stopDate
    const stopDate = moment(this.stopDate)
    const today = moment(new Date())

    this.hasActiveAccount = moment(stopDate).isAfter(today, 'day')
    this.getTableData()
  },
  methods: {
    editCoop: function(){
      setTimeout(() => {
            this.$router.push({ name: 'dashboard' })
          }, 1000)
    },
    isEditOk: function(item) {
      alert('move')
      /*if (item.approval_status < 3) {
        return true
      }  else {
        return false
      }*/
    },
    getTableData: function () {
      const postData = this.prePostData

      const data = { 'enumeratorId':this.memberData[0].id,'role':this.memberData[0].id,'mode':this.memberData[0].mode}
      const url = httpServ.getFarmerEnumerationtUrl('/wsg/drySession/getCooperativeDataTable')
      const resp = httpServ.postFetch(url,data)

      resp.then((result) => {
        this.tableData = result.data
      })
      this.tableLoading = false
    }
  }
};
</script>

<style>
.bg-div{
  background-color: #4CAF50 !important;
  color: white;
}
.b-start{
    border: 2px solid white !important;
    background-color: #4CAF50 !important;
    color: white !important;
}
h5{
    font-size: 30px;
}
p{
    font-size: 20px !important;
    font-weight: 300 !important;
}
</style>