<template>
  <Layout is-demo>
    <div class="">
      <h1 class="text-h4">Farmers' Picture</h1>
      <v-divider class="my-2"></v-divider>
    </div>
    <v-row class="pa-0 ma-0">
      <v-col sm="12" class="pa-0 ma-0">
        <v-card color="basil">
          <v-card-text>
            <v-row justify="center" align="center" dense>
              <v-col
                cols="12"
                sm="12"
                class="text-center"
              >
                <v-btn
                  rounded
                  block
                  color="primary"
                  large
                  @click="snap"
                >
                  Take Picture
                </v-btn>
              </v-col>
              <v-col 
                cols="12" 
                sm="12" 
                class="text-center"
              >
                <video 
                  id="video" 
                  width="220" 
                  height="140" 
                  autoplay 
                ></video>
                <v-col
                  v-if="pictureReady === true"
                  cols="12"
                  sm="12"
                  class="text-center"
                >
                  <v-btn
                    rounded
                    block
                    color="primary"
                    large
                    @click="uploadPicture"
                  >
                    Upload Picture
                  </v-btn>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                class="text-center"
              > <span class="caption"> {{ uploaededPercent }}</span>
                <br/>
                <canvas 
                  id="canvas" 
                  width="340" 
                  height="260"
                ></canvas>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5 green">
          Device Settings Warning
        </v-card-title>

        <v-card-text class="mt-3">
          You will be promted for permission to use your device camera. Please agree!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </Layout>
</template>

<script>
import AWS from 'aws-sdk'
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'

export default {
  components: {
    Layout
  },
  data() {
    return {
      memberData:{},
      currentLOBDetails:{},
      prePostData:{},
      pageData:{},
      snackbar: false,
      snackbartext:'',

      farmerId:'59de766a-006e-4fd9-bb05-5478f320c740',
      
      pictureReady: false,
      image_data_url:'',
      pictureUploaded: false,
      uploaededPercent:'0%',
      pictureExt:'',

      video:'',
      canvas:'',
      dialog:true
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.farmerData = httpServ.getSessionJson('farmerData')

    if (this.farmerData !== undefined) {
      if (this.farmerData.farmerId.length > 5) {
        this.farmerId = this.farmerData.farmerId
      }
    }
  },
  mounted() {
    this.canvas = document.querySelector('#canvas')
    setTimeout(() => {
      this.startCamera()
    }, 1000)
  },
  methods: {
    startCamera: async function() {
      const constraints = {
        video: {
          width: {
            min: 1280,
            ideal: 1920,
            max: 2560
          },
          height: {
            min: 720,
            ideal: 1080,
            max: 1440
          },
          facingMode: 'environment'
        }
      }

      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        this.snackbartext = 'This device does not support carame capture!'
        this.snackbar = true
      } else {
        navigator.mediaDevices.getUserMedia({ video: true })
        this.snackbartext = 'Setting up camera, please wait!'
        this.snackbar = true
        this.video = document.querySelector('#video')
        const stream = await navigator.mediaDevices.getUserMedia(constraints)

        this.video.srcObject = stream
        this.snackbar = fsalse
      }
    },
    snap: function() {
      this.pictureReady = true

      const canvas = document.querySelector('#canvas')

      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
   	  const image_data_url = this.canvas.toDataURL('image/jpeg')

   	  //console.log(image_data_url)
    },
    uploadPicture: function () {
      const pictureOk = true
      const ext = 'jpeg'
      const folder = 'farmers/demo_'//CHANGE THIS

      if (pictureOk) {
        const send_data = {
          //ourFile: this.image_data_url,
          ourEndpoint:
            process.env.AWS_ENDPOINT || process.env.VUE_APP_AWS_ENDPOINT,
          ourAccessKeyId:
            process.env.AWS_ACCESSKEYID || process.env.VUE_APP_AWS_ACCESSKEYID,
          ourSecretAccessKey:
            process.env.AWS_SECRETACCESSKEY ||
            process.env.VUE_APP_AWS_SECRETACCESSKEY,
          ourRegion: process.env.AWS_REGION || process.env.VUE_APP_AWS_REGION
        }
       
        const s3 = new AWS.S3({
          correctClockSkew: true,
          endpoint: send_data.ourEndpoint,
          accessKeyId: send_data.ourAccessKeyId,
          secretAccessKey: send_data.ourSecretAccessKey,
          region: send_data.ourRegion,
          logger: console
        })

        const blob = document.querySelector('#canvas').toBlob((blob) => {

          const uploadRequest = new AWS.S3.ManagedUpload({
            params: {
              Bucket: 'nfmp',
              Key: folder + this.farmerId + '.' + ext,
              Body: blob
            },
            service: s3
          })

          uploadRequest.on('httpUploadProgress', (event) => {
            const progressPercentage = Math.floor(
              (event.loaded * 100) / event.total
            )

            this.uploaededPercent = 'Uploaded Percent:' + progressPercentage

            //console.log('Upload progress ' + progressPercentage)
          })

          uploadRequest.send((err) => {
            //console.log('id2: ' + this.farmerId)
            if (err) {
              //console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2))
              this.snackbartext = 'Picture Upload error'
              this.snackbar = true
            } else {
              this.snackbartext = 'Picture Upload Ok!'
              this.snackbar = true
              this.pictureExt = ext
              this.savePictureExt()
            }
          })

        }, 'image/jpeg')
      } 
    },
    savePictureExt: function() {
      const formUrl = httpServ.getFarmerEnumerationtUrl('/wsg/drySession/saveFarmerPictureExt')
      const post_data = { 'farmerId':this.farmerId,'ext':this.pictureExt }
      const resp = httpServ.postFetch(formUrl,post_data)
      
      resp.then((result) => {
        //console.log('cooperative select is ', result)
        if (result.status === 200) {
          this.$router.push({ name: 'farmersVerifier' })
        } else {
          this.snackbartext = result.message
          this.snackbar = true
        }
      })
    },
    backToVerifier: function() {
      setTimeout(() => {
        this.$router.push({ name: 'farmersVerifier' })
      }, 1000)
    }
  }
  
}
</script>

<style scoped>
</style>
