<template>
  <div>
    <Picture />
  </div>
</template>

<script>
import Picture from '@/nfmp_components/Picture.vue'
export default {
  components: {
    Picture
  }
}
</script>