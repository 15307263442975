import { render, staticRenderFns } from "./MainPortalLayout.vue?vue&type=template&id=ac5d06e4&scoped=true&"
import script from "./MainPortalLayout.vue?vue&type=script&lang=js&"
export * from "./MainPortalLayout.vue?vue&type=script&lang=js&"
import style0 from "./MainPortalLayout.vue?vue&type=style&index=0&id=ac5d06e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac5d06e4",
  null
  
)

export default component.exports