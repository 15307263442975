<template>
  <div>
    <Dial />
  </div>
</template>

<script>
import Dial from '@/nfmp_components/Dial.vue'
export default {
  components: {
    Dial
  }
}                                      
</script>